import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, FastField } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button } from 'gatsby-theme-material-ui'
import { defaultFormStyles } from './shared'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),
  },
}))

export default function GaAccount(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        domain: initialValues.domain || '',
        measurementId: initialValues.measurementId || '',
        apiSecret: initialValues.apiSecret || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.domain) {
          errors.domain = 'Required'
        }
        if (!values.measurementId) {
          errors.measurementId = 'Required'
        }
        if (!values.apiSecret) {
          errors.apiSecret = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, ...formik }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <FastField
            component={TextField}
            name="domain"
            label="Domain"
            placeholder="example.com"
          />
          <FastField
            component={TextField}
            name="measurementId"
            label="Measurement ID"
            placeholder="G-123456"
          />
          <FastField
            component={TextField}
            name="apiSecret"
            label="API Secret"
          />

          <br />

          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create GA Account' : 'Update GA Account'}
            </Button>
            <Button
              to={isNew ? '/ga_accounts' : `/ga_accounts/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
