import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, FastField } from 'formik'
import { TextField } from 'formik-material-ui'
import { defaultFormStyles } from './shared'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),
  },
}))

export default function ConversionForm(props) {
  const { onSubmit } = props

  const initialValues = props.initialValues || {}

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        callbackHltid: initialValues.callbackHltid || '',
        callbackOfferId: initialValues.callbackOfferId || '',
        callbackGoalId: initialValues.callbackGoalId || '',
        callbackGoalName: initialValues.callbackGoalName || '',
        callbackOrderId: initialValues.callbackOrderId || '',
        callbackOrderSubTotal: initialValues.callbackOrderSubTotal || '',
        callbackOrderTax: initialValues.callbackOrderTax || '',
        callbackOrderShipping: initialValues.callbackOrderShipping || '',
        callbackOrderTotal: initialValues.callbackOrderTotal || '',
        callbackOrderCurrencyCode:
          initialValues.callbackOrderCurrencyCode || '',
        callbackOrderCouponCode: initialValues.callbackOrderCouponCode || '',
        callbackOrderDiscount: initialValues.callbackOrderDiscount || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.callbackOrderSubTotal) {
          errors.callbackOrderSubTotal = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        values.uuid = initialValues.callbackUuid
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, ...formik }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <FastField component={TextField} name="callbackHltid" label="HLTID" />
          <FastField
            component={TextField}
            name="callbackOfferId"
            label="Offer ID"
          />
          <FastField
            component={TextField}
            name="callbackOrderId"
            label="Order ID"
          />
          <FastField
            component={TextField}
            name="callbackGoalId"
            label="Goal ID"
          />
          <FastField
            component={TextField}
            type="number"
            name="callbackOrderSubTotal"
            label="Order sub total"
          />
          <FastField
            component={TextField}
            type="number"
            name="callbackOrderPayout"
            label="Order payout override"
          />
          <br />

          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {initialValues.callbackIsBad
                ? 'Update Conversion and re-send to TUNE'
                : 'Update Conversion'}
            </Button>
            <Button to={`/conversions/${initialValues.callbackUuid}`}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
