import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Title, Toast } from '../shared'
import * as API from '../../services/pingapi'

export default function ResourceNew(props) {
  const { resource, resourcePath, title, Form } = props

  const [message, setMessage] = useState('')

  const onSubmit = async (values) => {
    const response = await API.create(resource, values)
    if (response?.data?.success) {
      navigate(`/${resourcePath}`)
    } else if (response?.message) {
      setMessage(response.message)
    }
  }

  return (
    <>
      <Title title={`New ${title}`} />
      <Form isNew={true} onSubmit={onSubmit} />
      <Toast message={message} setMessage={setMessage} />
    </>
  )
}
