import React, { useState } from 'react'
import {
  Button as MaterialButton,
  Paper,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/moment'
import { isBlank } from '../../services/utils'

export const useFilterState = () => {
  const [filter, setFilter] = useState({})

  const submit = (values) => {
    const {
      startDate,
      endDate,
      advertiser,
      secretKey,
      domain,
      hltid,
      offerId,
      goalId,
      status,
      isBad,
    } = values

    const newFilter = {}
    if (startDate) newFilter.startDate = startDate.toISOString()
    if (endDate) newFilter.endDate = endDate.toISOString()
    if (advertiser) newFilter.advertiser = advertiser
    if (secretKey) newFilter.secretKey = secretKey
    if (domain) newFilter.domain = domain
    if (hltid) newFilter.hltid = hltid
    if (offerId) newFilter.offerId = offerId
    if (goalId) newFilter.goalId = goalId
    if (status) newFilter.status = status
    if (isBad) newFilter.isBad = isBad

    setFilter(newFilter)
  }

  const clear = () => {
    setFilter({})
  }

  return {
    filter,
    submit,
    clear,
  }
}

export default function ResourceTransactionFilter(props) {
  const { className, state } = props

  const [dirty, setDirty] = useState(false)

  const [startDate, setStartDate] = useState(moment().startOf('day'))
  const [endDate, setEndDate] = useState(null)
  const [dateWithTime, setDateWithTime] = useState(false)
  const changeStartDate = (newDate) => {
    setStartDate(newDate)
    changeEndDate(newDate.clone().add(1, 'day'))
  }
  const changeEndDate = (newDate) => {
    setEndDate(newDate)
  }

  const [advertiser, setAdvertiser] = useState('')
  const [secretKey, setSecretKey] = useState('')
  const [domain, setDomain] = useState('')
  const [hltid, setHltid] = useState('')
  const [offerId, setOfferId] = useState('')
  const [goalId, setGoalId] = useState('')
  const [status, setStatus] = useState('')
  const [isBad, setIsBad] = useState('')

  const submit = () => {
    setDirty(false)
    state.submit({
      startDate,
      endDate,
      advertiser,
      secretKey,
      domain,
      hltid,
      offerId,
      goalId,
      status,
      isBad,
    })
  }

  const clear = () => {
    setStartDate(moment().startOf('day'))
    setEndDate(null)
    setAdvertiser('')
    setSecretKey('')
    setDomain('')
    setHltid('')
    setOfferId('')
    setGoalId('')
    setStatus('')
    setIsBad('')
    setDirty(false)
    state.clear()
  }

  return (
    <Paper
      className={className}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          submit()
        }
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          {dateWithTime ? (
            <>
              <Grid item>
                <DateTimePicker
                  value={startDate}
                  onChange={(newDate) => {
                    setDirty(true)
                    changeStartDate(newDate)
                  }}
                  autoOk={true}
                  ampm={false}
                  maxDate={new Date()}
                  label="Start date"
                  format="DD/MM/YYYY HH:mm"
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  value={endDate}
                  onChange={(newDate) => {
                    setDirty(true)
                    changeEndDate(newDate)
                  }}
                  autoOk={true}
                  ampm={false}
                  maxDate={new Date()}
                  label="End date"
                  format="DD/MM/YYYY HH:mm"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <DatePicker
                  value={startDate}
                  onChange={(newDate) => {
                    setDirty(true)
                    changeStartDate(newDate)
                  }}
                  autoOk={true}
                  ampm={false}
                  maxDate={new Date()}
                  label="Start date"
                  format="DD/MM/YYYY"
                />
              </Grid>
              <Grid item>
                <DatePicker
                  value={endDate}
                  onChange={(newDate) => {
                    setDirty(true)
                    changeEndDate(newDate)
                  }}
                  autoOk={true}
                  ampm={false}
                  maxDate={new Date()}
                  label="End date"
                  format="DD/MM/YYYY"
                />
              </Grid>
            </>
          )}
          <Grid item>
            <MaterialButton
              color="primary"
              variant={dateWithTime ? 'contained' : 'outlined'}
              onClick={() => {
                setDateWithTime(!dateWithTime)
                if (startDate) setStartDate(moment(startDate).startOf('day'))
                if (endDate) setEndDate(moment(endDate).startOf('day'))
              }}
              style={{
                position: 'relative',
                top: 8,
              }}
            >
              Time
            </MaterialButton>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            label="Domain"
            value={domain}
            onChange={(event) => {
              setDirty(true)
              setDomain(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Advertiser"
            value={advertiser}
            onChange={(event) => {
              setDirty(true)
              setAdvertiser(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="HLTID"
            value={hltid}
            onChange={(event) => {
              setDirty(true)
              setHltid(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Goal ID"
            value={goalId}
            onChange={(event) => {
              setDirty(true)
              setGoalId(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Offer ID"
            value={offerId}
            onChange={(event) => {
              setDirty(true)
              setOfferId(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Secret key"
            value={secretKey}
            onChange={(event) => {
              setDirty(true)
              setSecretKey(event.target.value)
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item className="status-select">
          <InputLabel shrink={!!status}>Status</InputLabel>
          <Select
            label="Status"
            value={status}
            onChange={(event) => {
              setDirty(true)
              setStatus(event.target.value)
            }}
          >
            <MenuItem value="">Status</MenuItem>
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="ERROR">ERROR</MenuItem>
            <MenuItem value="IGNORED">IGNORED</MenuItem>
          </Select>
        </Grid>
        <Grid item className="status-select">
          <InputLabel shrink={!!isBad}>Safeguard</InputLabel>
          <Select
            label="Safeguard"
            value={isBad}
            onChange={(event) => {
              setDirty(true)
              setIsBad(event.target.value)
            }}
          >
            <MenuItem value="">Safeguard</MenuItem>
            <MenuItem value="true">ALERT</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="actions">
        <Grid item>
          {!isBlank(state.filter) && (
            <MaterialButton color="secondary" onClick={clear}>
              Clear
            </MaterialButton>
          )}
        </Grid>
        <Grid item>
          <MaterialButton color="primary" variant="contained" onClick={submit}>
            {dirty ? 'Filter' : 'Refresh'}
          </MaterialButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
