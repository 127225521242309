import React from 'react'
import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

export const createResource = (props) => {
  const { resource, title, listFields, showFields, details, form, filter } =
    props
  const resourcePath = props.resourcePath || props.resource
  const Details = details
  const Form = form

  return {
    List: (props) => (
      <List
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        fields={listFields}
        filter={filter}
        {...props}
      />
    ),
    Show: (props) => (
      <Show
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        fields={showFields}
        Details={Details}
        {...props}
      />
    ),
    New: (props) => (
      <New
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        Form={Form}
        {...props}
      />
    ),
    Edit: (props) => (
      <Edit
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        Form={Form}
        {...props}
      />
    ),
  }
}
