import React from 'react'
import List from './List'
import Show from './Show'
import New from './New'
import Edit from './Edit'

export const createEventResource = (props) => {
  const {
    resource,
    resourceKey,
    title,
    listFields,
    showFields,
    details,
    form,
  } = props
  const resourcePath = props.resourcePath || props.resource
  const Details = details
  const Form = form

  return {
    List: (props) => (
      <List
        resource={resource}
        resourceKey={resourceKey}
        resourcePath={resourcePath}
        title={title}
        fields={listFields}
        {...props}
      />
    ),
    Show: (props) => (
      <Show
        resource={resource}
        title={title}
        fields={showFields}
        Details={Details}
        {...props}
      />
    ),
    New: (props) => (
      <New
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        Form={Form}
        {...props}
      />
    ),
    Edit: (props) => (
      <Edit
        resource={resource}
        resourcePath={resourcePath}
        title={title}
        Form={Form}
        {...props}
      />
    ),
  }
}
