import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { CircularProgress } from '@material-ui/core'
import { Title, Toast } from '../shared'
import * as API from '../../services/pingapi'

export default function ResourceEdit(props) {
  const { uuid, resource, resourcePath, title, Form } = props

  const [record, setRecord] = useState(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!record) {
      API.one(resource, { uuid }).then((response) => {
        if (response) {
          setRecord(response.data.record)
        }
      })
    }
  }, [resource, record, uuid])

  if (!record) {
    return <CircularProgress />
  }

  const onSubmit = async (values, raw = false) => {
    const response = await API.update(resource, uuid, values, raw)
    if (response?.data?.success) {
      navigate(`/${resourcePath}/${uuid}`)
    } else if (response?.message) {
      setMessage(response.message)
    }
  }

  return (
    <>
      <Title title={`Edit ${title}`} />
      <Form isNew={false} initialValues={record} onSubmit={onSubmit} />
      <Toast message={message} setMessage={setMessage} />
    </>
  )
}
