import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { Button } from 'gatsby-theme-material-ui'
import { uniq } from 'lodash'
import { Formik, Form, Field, FastField } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { defaultFormStyles, SelectField, RichEditor } from './shared'
import * as API from '../../services/api'

const groupOptions = [
  { label: 'Inbound', value: 'inbound' },
  { label: 'Outbound', value: 'outbound' },
  { label: 'Partner Network', value: 'partner' },
]

const slugLabel = (category) => {
  switch (category) {
    case 'cms':
      return 'CMS name'
    case 'help':
      return 'Article slug'
    default:
      return 'Article slug'
  }
}

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },

    '& .MuiAutocomplete-root': {
      marginBottom: 0,
    },

    '& .hint': {
      padding: '0 8px',
      marginTop: -4,
      marginBottom: 8,
      color: '#dd2200',
      fontSize: 13,
    },
  },

  autocompleteSelect: {
    marginTop: '16px !important',
    marginBottom: 0,

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 0,
      marginRight: 0,
    },

    '& .MuiButtonBase-root': {
      position: 'relative',
      top: 16,
    },
  },

  bodyContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '& h2': {
      textAlign: 'left',
    },

    '& > div': {
      border: '1px solid #919191',
      borderRadius: 3,
      marginTop: theme.spacing(3),

      '& .public-DraftEditor-content': {
        minHeight: 300,
      },
    },

    '& .code': {
      display: 'inline',
      padding: '2px 6px',
      color: '#e53a36',
      background: '#f4f5f6',
      border: '1px solid #dfe1e2',
      borderRadius: '4px',
    },
  },
}))

export default function DocArticle(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [articles, setArticles] = useState(null)
  const [takenSlugs, setTakenSlugs] = useState([])

  useEffect(() => {
    if (articles === null) {
      API.all('doc_articles', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        setArticles(response?.data?.records)
        setTakenSlugs(
          response?.data?.records
            ?.filter((article) => article.uuid !== initialValues.uuid)
            ?.map(
              (article) =>
                article.group.toLowerCase() + ':' + article.slug.toLowerCase()
            )
        )
      })
    }
  }, [articles])

  if (articles === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        isPublished: !!initialValues.isPublished || false,
        isPopular: !!initialValues.isPopular || false,
        group: initialValues.group || 'inbound',
        category: initialValues.category || '',
        subCategory: initialValues.subCategory || '',
        slug: initialValues.slug || '',
        title: initialValues.title || '',
        shortTitle: initialValues.shortTitle || '',
        body: initialValues.body || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.slug) {
          errors.slug = 'Required'
        } else if (
          takenSlugs.includes(
            values.group.toLowerCase() + ':' + values.slug.toLowerCase()
          )
        ) {
          errors.slug = 'Taken'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (values.category === 'No category') {
          values.category = ''
        }

        await onSubmit(values)
        setSubmitting(false)
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isPublished"
            onChange={(event) =>
              setFieldValue('isPublished', event.target.checked)
            }
            Label={{ label: 'Published' }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isPopular"
            onChange={(event) =>
              setFieldValue('isPopular', event.target.checked)
            }
            Label={{ label: 'Popular' }}
          />
          <SelectField
            name="group"
            label="Main category"
            initialValue={initialValues.group || 'inbound'}
            values={groupOptions}
          />
          <Autocomplete
            className={classes.autocompleteSelect}
            name="category"
            size="small"
            options={uniq(articles?.map((article) => article.category) || [])}
            getOptionLabel={(value) => {
              return value || 'No category'
            }}
            freeSolo={true}
            defaultValue={props.initialValues?.category}
            onChange={(event, value) => {
              setFieldValue('category', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="category"
                label="Category"
                {...inputProps}
              />
            )}
          />
          <Autocomplete
            className={classes.autocompleteSelect}
            name="subCategory"
            size="small"
            options={uniq(
              articles?.map((article) => article.subCategory) || []
            )}
            getOptionLabel={(value) => {
              return value || ''
            }}
            freeSolo={true}
            defaultValue={props.initialValues?.subCategory}
            onChange={(event, value) => {
              setFieldValue('subCategory', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="subCategory"
                label="Sub category"
                {...inputProps}
              />
            )}
          />

          <FastField
            component={TextField}
            name="slug"
            type="text"
            label={slugLabel(values.category)}
          />
          <FastField
            component={TextField}
            name="title"
            type="text"
            label="Title"
          />
          <FastField
            component={TextField}
            name="shortTitle"
            type="text"
            label="Short title"
          />

          <div className={classes.bodyContainer}>
            <span className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
              Article body
            </span>
            <RichEditor
              initialValue={initialValues.body}
              onChange={(value) => setFieldValue('body', value)}
            />
          </div>
          <div className="hint">
            Use shift+enter to insert newlines in scripts and lists.
          </div>
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Article' : 'Update Article'}
            </Button>
            <Button
              to={
                isNew ? '/doc_articles' : `/doc_articles/${initialValues.uuid}`
              }
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
