import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { uniqBy, compact } from 'lodash'
import { Title } from '../shared'
import { toUnicode } from 'punycode'
// import * as API from '../../services/api'
import * as ScriptManagerAPI from '../../services/scriptapi'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: 4,
    boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.2)',
  },

  urlsInput: {
    width: '100%',
  },

  actionBtn: {},

  channelFilter: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 8,
    marginBottom: 16,
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,

    '&:hover': {
      borderColor: '#000000',
    },
  },

  channelSelect: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    borderColor: '#c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,
    appearance: 'none',

    '&:hover': {
      borderColor: '#000000',
    },
  },

  channelSelectPin: {
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 14,
      right: 16,
      width: 8,
      height: 8,
      borderRight: '2px solid #c0c0c0',
      borderBottom: '2px solid #c0c0c0',
      transform: 'rotate(45deg)',
      pointerEvents: 'none',
    },
  },

  countryFilter: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 16,
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,

    '&:hover': {
      borderColor: '#000000',
    },
  },

  output: {
    border: '1px solid gray',
    borderRadius: 4,
    padding: theme.spacing(2),
    whiteSpace: 'pre',
    overflow: 'auto',
  },

  outputRow: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default function OutboundChecker(props) {
  const classes = useStyles()

  const [queues, setQueues] = useState(null)

  useEffect(() => {
    if (queues === null) {
      ScriptManagerAPI.get('internal/queues').then((response) => {
        if (response) {
          setQueues(response)
        }
      })
    }
  }, [])

  if (!queues) {
    return (
      <>
        <Title title="Conversion queue" />
      </>
    )
  }

  return (
    <>
      <Title title="Conversion queue" />
      <Paper className={classes.container}>
        <Content classes={classes} queues={queues} />
      </Paper>
    </>
  )
}

const Content = (props) => {
  const { classes, queues } = props

  console.log(queues)

  return (
    <div>
      <div />
    </div>
  )
}
