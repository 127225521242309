import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { Button } from 'gatsby-theme-material-ui'
import { uniq } from 'lodash'
import { Formik, Form, Field } from 'formik'
import {
  TextField,
  SimpleFileUpload,
  CheckboxWithLabel,
} from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },

    '& .MuiAutocomplete-root': {
      marginBottom: 0,
    },

    '& .hint': {
      padding: '0 8px',
      marginTop: -4,
      marginBottom: 8,
      color: '#dd2200',
      fontSize: 13,
    },
  },

  autocompleteSelect: {
    marginTop: '16px !important',
    marginBottom: 0,

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 0,
      marginRight: 0,
    },

    '& .MuiButtonBase-root': {
      position: 'relative',
      top: 16,
    },
  },

  bodyContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '& h2': {
      textAlign: 'left',
    },

    '& > div': {
      border: '1px solid #919191',
      borderRadius: 3,
      marginTop: theme.spacing(3),

      '& .public-DraftEditor-content': {
        minHeight: 300,
      },
    },
  },
}))

export default function DocCms(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [articles, setArticles] = useState(null)

  useEffect(() => {
    if (articles === null) {
      API.all('doc_articles', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        setArticles(response?.data?.records)
      })
    }
  }, [articles])

  if (articles === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        isActive: !!initialValues.isActive || false,
        isPopular: !!initialValues.isPopular || false,
        docArticleUuid: initialValues.docArticleUuid || null,
        name: initialValues.name || '',
        logoFile: initialValues.logoFile || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (!values.docArticleUuid) {
          values.docArticleUuid = null
        }

        if (values.logoFile) {
          const data = new FormData()
          data.append('file', values.logoFile)
          const response = await API.upload('doc_cms', data)
          values.logoUrl = response.data.url
        }

        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isActive"
            onChange={(event) =>
              setFieldValue('isActive', event.target.checked)
            }
            Label={{ label: 'Active' }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isPopular"
            onChange={(event) =>
              setFieldValue('isPopular', event.target.checked)
            }
            Label={{ label: 'Popular' }}
          />
          <Autocomplete
            className={classes.autocompleteSelect}
            name="docArticleUuid"
            size="small"
            options={[
              '',
              ...uniq(articles?.map((article) => article.uuid) || []),
            ]}
            getOptionLabel={(value) => {
              const article = articles.find((article) => article.uuid === value)
              return article?.title || 'No article'
            }}
            defaultValue={props.initialValues?.docArticleUuid}
            onChange={(event, value) => {
              setFieldValue('docArticleUuid', value)
            }}
            renderInput={(inputProps) => (
              <Field
                component={TextField}
                variant="outlined"
                size="small"
                name="docArticleUuid"
                label="Related doc article"
                {...inputProps}
              />
            )}
          />

          <Field component={TextField} name="name" type="text" label="Name" />
          <Field
            component={SimpleFileUpload}
            name="logoFile"
            type="file"
            label="CMS logo"
          />
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create CMS' : 'Update CMS'}
            </Button>
            <Button to={isNew ? '/doc_cms' : `/doc_cms/${initialValues.uuid}`}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
