import React, { useState, useEffect } from 'react'
import {
  Button as MaterialButton,
  LinearProgress,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field, FastField, FieldArray } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { defaultFormStyles, SelectField } from './shared'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        paddingTop: theme.spacing(1),
      },
    },

    '& .checkbox-container': {
      marginTop: -16,
    },

    '& .MuiAutocomplete-root': {
      marginTop: 32,
    },

    '& .hidden': {
      display: 'none',
    },

    '& .contract-info': {
      marginTop: 8,
      marginBottom: -8,
    },

    '& .swap-trigger-btn': {
      display: 'block',
      height: 16,
      padding: 0,
      margin: '0 auto',

      '&.hidden': {
        display: 'block',
        opacity: 0,
        pointerEvents: 'none',
      },
    },

    '& .remove-btn': {
      minWidth: 36,
      marginTop: 8,
    },
  },
  fieldset: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  fieldrow: {
    '& .MuiGrid-root': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  hint: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.info.light}`,
    color: theme.palette.grey[700],
  },
  autocompleteSelect: {
    marginBottom: 8,

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 0,
      marginRight: 0,
    },

    '& .MuiButtonBase-root': {
      position: 'relative',
      top: 16,
    },
  },
}))

export default function ConversionRuleSetForm(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [conversionRules, setConversionRules] = useState(null)

  useEffect(() => {
    if (!conversionRules) {
      API.form('conversion_rule_sets').then((response) => {
        setConversionRules(response?.data?.conversionRules || [])
      })
    }
  }, [conversionRules])

  if (conversionRules === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        isActive: !!initialValues.isActive || true,
        name: initialValues.name || '',
        abbr: initialValues.abbr || '',
        description: initialValues.description || '',
        conversionRuleUuids: initialValues.conversionRuleUuids || [],
      }}
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        if (!values.abbr) {
          errors.abbr = 'Required'
        }
        if (!values.description) {
          errors.description = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <Form className={classes.form} acceptCharset="UTF-8">
            <Grid container justify="center" alignItems="center">
              <Grid item sm={12} md={9}>
                <FastField
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="name"
                  label="Group name"
                />

                <FastField
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="abbr"
                  label="Group icon"
                />

                <FastField
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="description"
                  label="Group description"
                />

                <div className="checkbox-container">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="isActive"
                    onChange={(event) =>
                      setFieldValue('isActive', event.target.checked)
                    }
                    Label={{
                      label: 'Active',
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.fieldset}>
              Rules
            </Typography>
            <FieldArray name="conversionRuleUuids">
              {({ swap, remove, push }) => (
                <>
                  {values.conversionRuleUuids.map(
                    (conversionRuleUuid, index) => (
                      <Grid
                        key={index}
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.fieldrow}
                      >
                        <Grid item sm={8}>
                          <SelectField
                            name={`conversionRuleUuids.${index}`}
                            label="Attribution rule"
                            initialValue={undefined}
                            values={conversionRules.map((rule) => ({
                              label: rule.name,
                              value: rule.uuid,
                            }))}
                          />
                        </Grid>
                        <Grid item sm={1}>
                          <MaterialButton
                            onClick={() => remove(index)}
                            className="remove-btn"
                          >
                            ✕
                          </MaterialButton>
                        </Grid>
                      </Grid>
                    )
                  )}

                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    className={classes.fieldrow}
                  >
                    <Grid item md={12}>
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        onClick={() => push('')}
                      >
                        Add attribution rule
                      </MaterialButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </FieldArray>

            <br />
            {isSubmitting && <LinearProgress />}
            <div className={classes.formActions}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {isNew ? 'Create New Group' : 'Update Group'}
              </Button>
              <Button
                to={
                  isNew
                    ? '/attribution_presets'
                    : `/attribution_presets/${initialValues.uuid}`
                }
              >
                Cancel
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
