import React, { useState } from 'react'
import {
  Button as MaterialButton,
  Paper,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { isBlank } from '../../services/utils'

const startDateStorageKey = 'events-start-date'
const endDateStorageKey = 'events-end-date'

export const useFilterState = () => {
  const [filter, setFilter] = useState({})

  const submit = (values) => {
    const { startDate, endDate, hltid, offerId, goalId, status } = values

    const newFilter = {}
    if (startDate) newFilter.startDate = startDate.toISOString()
    if (endDate) newFilter.endDate = endDate.toISOString()
    if (hltid) newFilter.hltid = hltid
    if (offerId) newFilter.offerId = offerId
    if (goalId) newFilter.goalId = goalId
    if (status) newFilter.status = status

    setFilter(newFilter)
  }

  const clear = () => {
    setFilter({})
  }

  return {
    filter,
    submit,
    clear,
  }
}

export default function ResourceTransactionFilter(props) {
  const { className, state } = props

  const [dirty, setDirty] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const changeStartDate = (newDate) => {
    setStartDate(newDate)
    localStorage.setItem(
      startDateStorageKey,
      JSON.stringify({
        value: newDate,
        created: new Date().toISOString().substring(0, 10),
      })
    )
  }

  const [endDate, setEndDate] = useState(null)
  const changeEndDate = (newDate) => {
    setEndDate(newDate)
    localStorage.setItem(
      endDateStorageKey,
      JSON.stringify({
        value: newDate,
        created: new Date().toISOString().substring(0, 10),
      })
    )
  }

  const [hltid, setHltid] = useState('')
  const [offerId, setOfferId] = useState('')
  const [goalId, setGoalId] = useState('')
  const [status, setStatus] = useState('')

  const submit = () => {
    setDirty(false)
    state.submit({
      startDate,
      endDate,
      hltid,
      offerId,
      goalId,
      status,
    })
  }

  const clear = () => {
    setStartDate(null)
    setEndDate(null)
    setHltid('')
    setOfferId('')
    setGoalId('')
    setStatus('')
    setDirty(false)
    state.clear()
  }

  return (
    <Paper
      className={className}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          submit()
        }
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item>
            <DateTimePicker
              value={startDate}
              onChange={(newDate) => {
                setDirty(true)
                changeStartDate(newDate)
              }}
              autoOk={true}
              ampm={false}
              maxDate={new Date()}
              label="Start date"
              format="DD/MM/YYYY HH:mm"
            />
          </Grid>
          <Grid item>
            <DateTimePicker
              value={endDate}
              onChange={(newDate) => {
                setDirty(true)
                changeEndDate(newDate)
              }}
              autoOk={true}
              ampm={false}
              maxDate={new Date()}
              label="End date"
              format="DD/MM/YYYY HH:mm"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            label="HLTID"
            value={hltid}
            onChange={(event) => {
              setDirty(true)
              setHltid(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Goal ID"
            value={goalId}
            onChange={(event) => {
              setDirty(true)
              setGoalId(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Offer ID"
            value={offerId}
            onChange={(event) => {
              setDirty(true)
              setOfferId(event.target.value)
            }}
          />
        </Grid>
        <Grid item className="status-select">
          <InputLabel shrink={!!status}>Status</InputLabel>
          <Select
            label="Status"
            value={status}
            onChange={(event) => {
              setDirty(true)
              setStatus(event.target.value)
            }}
          >
            <MenuItem value="">Status</MenuItem>
            <MenuItem value="done">Converted</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="actions">
        <Grid item>
          {!isBlank(state.filter) && (
            <MaterialButton color="secondary" onClick={clear}>
              Clear
            </MaterialButton>
          )}
        </Grid>
        <Grid item>
          <MaterialButton
            color="primary"
            variant="contained"
            disabled={!dirty}
            onClick={submit}
          >
            Filter
          </MaterialButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
