import { authToken, logout } from './authentication'
import { toQuery } from './utils'

const apiHost = process.env.PING_HOST
const apiKey = process.env.PING_KEY
const apiPathPrefix = '/ping'

const progress = {}
const retryDelay = 1500

const apiFetch = (path, options) => {
  if (progress[path]) {
    return Promise.resolve(null)
  }
  progress[path] = true

  options = options || {}
  options.method = options.method || 'GET'
  options.headers = options.headers || {
    'api-key': apiKey,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  options.headers['X-Access-Token'] = authToken()

  return window
    .fetch(`${apiHost}${apiPathPrefix}${path}`, options)
    .then((raw) => {
      authorize(raw.status)
      progress[path] = false
      return raw.json()
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      setTimeout(() => {
        progress[path] = false
      }, retryDelay)
    })
}

let unauthCount = 0
const maxUnauth = 2
const authorize = (status) => {
  if (status === 401) {
    unauthCount++
    if (unauthCount >= maxUnauth) {
      logout()
      window.location.assign('/')
    }
  } else {
    unauthCount = 0
  }
}

export const all = (resource, params) => {
  return apiFetch(`/admin/${resource}?${toQuery(params)}`)
}

export const one = (resource, params) => {
  const { uuid } = params

  return apiFetch(`/admin/${resource}/${uuid}`)
}

export const form = (resource) => {
  return apiFetch(`/admin/${resource}/form`)
}

export const create = (resource, params) => {
  return apiFetch(`/admin/${resource}`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export const update = (resource, uuid, params) => {
  return apiFetch(`/admin/${resource}/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
}

export const destroy = (resource, uuid) => {
  return apiFetch(`/admin/${resource}/${uuid}`, {
    method: 'DELETE',
  })
}

export const upload = (resource, data, path = '') => {
  return apiFetch(`/admin/${resource}/upload/${path}`, {
    method: 'POST',
    body: data,
    headers: {},
  })
}

export const get = (resource) => {
  return apiFetch(`/admin/${resource}`, {
    method: 'GET',
  })
}

export const post = (resource, data) => {
  return apiFetch(`/admin/${resource}`, {
    method: 'POST',
    body: data,
  })
}

export const put = (resource, data) => {
  return apiFetch(`/admin/${resource}`, {
    method: 'PUT',
    body: data,
  })
}
