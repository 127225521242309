import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, FastField } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button } from 'gatsby-theme-material-ui'
import { defaultFormStyles } from './shared'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),
  },
}))

export default function GaAttribution(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        param: initialValues.param || '',
        utmSource: initialValues.utmSource || '',
        utmMedium: initialValues.utmMedium || '',
        utmCustom: initialValues.utmCustom || '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.param) {
          errors.param = 'Required'
        }
        if (!values.utmSource) {
          errors.utmSource = 'Required'
        }
        if (!values.utmMedium) {
          errors.utmMedium = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, ...formik }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <FastField
            component={TextField}
            name="param"
            label="URL Param"
            placeholder="hltid"
          />
          <FastField
            component={TextField}
            name="utmSource"
            label="UTM Source"
            placeholder="heylink"
          />
          <FastField
            component={TextField}
            name="utmMedium"
            label="UTM Medium"
            placeholder="cpc"
          />
          <FastField
            component={TextField}
            name="utmCustom"
            label="Additional params"
            placeholder="utm_campaign=campaign&utm_content=content"
          />

          <br />

          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create GA Attribution' : 'Update GA Attribution'}
            </Button>
            <Button
              to={
                isNew
                  ? '/ga_attributions'
                  : `/ga_attributions/${initialValues.uuid}`
              }
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
