import { toQuery } from './utils'

const apiHost = process.env.SCRIPTMANAGER_HOST
const apiKey = process.env.SCRIPTMANAGER_KEY
const apiPathPrefix = '/scriptmanager/v1'

const progress = {}
const retryDelay = 1500

const apiFetch = (path, options) => {
  if (progress[path]) {
    return Promise.resolve(null)
  }
  progress[path] = true

  options = options || {}
  options.method = options.method || 'GET'
  options.headers = options.headers || {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }

  return window
    .fetch(`${apiHost}${apiPathPrefix}${path}`, options)
    .then((raw) => {
      progress[path] = false
      return raw.json()
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      setTimeout(() => {
        progress[path] = false
      }, retryDelay)
    })
}

export const all = (resource, params) => {
  return apiFetch(`/${resource}?${toQuery(params)}`)
}

export const one = (resource, params) => {
  const { uuid } = params

  return apiFetch(`/${resource}/${uuid}`)
}

export const form = (resource) => {
  return apiFetch(`/${resource}/form`)
}

export const create = (resource, params) => {
  return apiFetch(`/${resource}`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export const update = (resource, uuid, params) => {
  return apiFetch(`/${resource}/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
}

export const destroy = (resource, uuid) => {
  return apiFetch(`/${resource}/${uuid}`, {
    method: 'DELETE',
  })
}

export const upload = (resource, data, path = '') => {
  return apiFetch(`/${resource}/upload/${path}`, {
    method: 'POST',
    body: data,
    headers: {},
  })
}

export const get = (resource) => {
  return apiFetch(`/${resource}`, {
    method: 'GET',
  })
}

export const post = (resource, data) => {
  return apiFetch(`/${resource}`, {
    method: 'POST',
    body: data,
  })
}

export const put = (resource, data) => {
  return apiFetch(`/${resource}`, {
    method: 'PUT',
    body: data,
  })
}
