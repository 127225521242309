import React, { useState, useEffect } from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },
  },
}))

export default function ShortlinkForm(props) {
  const { isNew, onSubmit } = props
  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [contracts, setContracts] = useState(null)

  useEffect(() => {
    if (!contracts) {
      API.all('contracts', {
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
      }).then((response) => {
        if (response?.data?.records) {
          setContracts(response.data.records)
        }
      })
    }
  }, [contracts])

  if (contracts === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        contractUuid: initialValues.contractUuid || '',
        name: initialValues.name || '',
        targetUrl: initialValues.targetUrl || '',
        isPrivate: !!initialValues.isPrivate || false,
      }}
      validate={(values) => {
        const errors = {}
        if (!values.contractUuid) {
          errors.contractUuid = 'Required'
        } else if (!values.name) {
          errors.name = 'Required'
        } else if (!values.targetUrl) {
          errors.targetUrl = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Form className={classes.form} acceptCharset="UTF-8">
          <>
            <Autocomplete
              name="contractUuid"
              label="Campaign"
              options={contracts?.map((contract) => contract.uuid)}
              getOptionLabel={(value) => {
                const contract = contracts.find(
                  (contract) => contract.uuid === value
                )
                return contract.code || 'Unnamed contract'
              }}
              defaultValue={initialValues?.contractUuid}
              onChange={(event, value) => {
                setFieldValue('contractUuid', value)
              }}
              renderInput={(inputProps) => (
                <Field
                  component={TextField}
                  name="contractUuid"
                  label="Campaign"
                  {...inputProps}
                />
              )}
            />
            <Field component={TextField} name="name" type="text" label="Name" />
            <Field
              component={TextField}
              name="targetUrl"
              type="text"
              label="Target URL"
            />
          </>
          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Smart Link' : 'Update Smart Link'}
            </Button>
            <Button
              to={isNew ? '/shortlinks' : `/shortlinks/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
