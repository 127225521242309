import React, { useState, useEffect, useCallback, useRef } from 'react'
import { FormControl, MenuItem, Grid, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FastField } from 'formik'
import { TextField, Select } from 'formik-material-ui'
import {
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  convertFromHTML,
} from 'draft-js'
// import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import createImagePlugin from '@draft-js-plugins/image'
import { authToken } from '../../services/authentication'

import 'draft-js/dist/Draft.css'

let Jodit = null
let JoditEditor = null

export const defaultFormStyles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: 4,
  boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.2)',

  '& h2': {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  '& .MuiTextField-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  '& .MuiInputBase-root': {
    width: '100%',
  },

  '& .MuiButtonBase-root': {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  '& .MuiLinearProgress-root': {
    height: 4,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 2,
    marginBottom: -6,
  },

  '& .MuiAutocomplete-root': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  '& .MuiAutocomplete-root .MuiTextField-root': {
    margin: 0,
  },

  '& .MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
    marginTop: -theme.spacing(2),
  },
})

const useDefaultFormStyles = makeStyles((theme) => ({
  form: { ...defaultFormStyles(theme) },

  defaultSelect: {
    '& .MuiFormLabel-root': {
      top: -4,
      left: 14,
    },
    '& .MuiInputBase-root': {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiSelect-root': {
      padding: '10px 16px',
    },
  },
}))

export const PeriodField = (props) => {
  return (
    <Grid container>
      <Grid item sm={6} md={8}>
        <FastField
          component={TextField}
          variant="outlined"
          size="small"
          name={props.name}
          label={props.label}
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FormControl className={props.className}>
          <FastField
            component={Select}
            variant="outlined"
            name={props.name + 'Type'}
          >
            <MenuItem value="days">days</MenuItem>
            <MenuItem value="weeks">weeks</MenuItem>
            <MenuItem value="months">months</MenuItem>
          </FastField>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export const SelectField = (props) => {
  const { name, label, values, initialValue } = props

  const classes = useDefaultFormStyles()
  const id = name.toLowerCase().replace(/\W/g, '_').replace(/^_|_$/g, '')

  return (
    <FormControl className={classes.defaultSelect}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <FastField
        component={Select}
        className={classes.select}
        variant="outlined"
        size="small"
        name={name}
        label={label}
        defaultValue={initialValue}
        inputProps={{
          id,
        }}
      >
        {values.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FastField>
    </FormControl>
  )
}

const richEditorButtons = [
  {
    'group': 'source',
    'buttons': [],
  },
  {
    'group': 'font-style',
    'buttons': [],
  },
  {
    name: 'code',
    iconURL: '/images/brackets.svg',
    tooltip: 'Monospace code block',
    list: {
      block: 'Code block',
      inline: 'Inline code',
    },
    exec: function (editor) {
      const key = this.name
      if (key === 'block' || key === 'code') {
        editor.execCommand('formatBlock', false, 'code')
      } else if (key === 'inline') {
        const selection = window.getSelection().toString()
        const element = `<span class="code">${selection}</span>`
        document.execCommand('insertHTML', false, element)
      }
    },
    isActive: (editor) => {
      const current = editor.s.current()
      // block
      const isBlock = Boolean(
        current && Jodit.modules.Dom.closest(current, 'code', editor.editor)
      )
      // inline
      const closest =
        current && Jodit.modules.Dom.closest(current, 'span', editor.editor)
      const isInline = Boolean(closest && closest.classList.contains('code'))
      return isBlock || isInline
    },
  },
  {
    name: 'info',
    iconURL: '/images/info.svg',
    tooltip: 'Info block',
    list: {
      notice: 'Notice block',
      warning: 'Warning block',
    },
    exec: function (editor) {
      const key = this.name
      const selection = window.getSelection().toString()
      if (key === 'notice' || key === 'info') {
        const element = `<span class="info-notice">${selection}</span>`
        document.execCommand('insertHTML', false, element)
      } else if (key === 'warning') {
        const element = `<span class="info-warning">${selection}</span>`
        document.execCommand('insertHTML', false, element)
      }
    },
    isActive: (editor) => {
      const current = editor.s.current()
      const closest =
        current && Jodit.modules.Dom.closest(current, 'span', editor.editor)
      return (
        Boolean(closest && closest.classList.contains('info-notice')) ||
        Boolean(closest && closest.classList.contains('info-warning'))
      )
    },
  },
  {
    'group': 'script',
    'buttons': [],
  },
  {
    'group': 'list',
    'buttons': ['ul', 'ol'],
  },
  {
    'group': 'indent',
    'buttons': [],
  },
  {
    'group': 'font',
    'buttons': [],
  },
  {
    'group': 'color',
    'buttons': [],
  },
  '\n',
  {
    'group': 'media',
    'buttons': [],
  },
  {
    'group': 'state',
    'buttons': [],
  },
  // {
  //   'group': 'clipboard',
  //   'buttons': [],
  // },
  {
    'group': 'insert',
    'buttons': [],
  },
  {
    'group': 'form',
    'buttons': [],
  },
  {
    'group': 'history',
    'buttons': [],
  },
  // {
  //   'group': 'search',
  //   'buttons': [],
  // },
  // {
  //   'group': 'other',
  //   'buttons': [],
  // },
  // {
  //   'group': 'info',
  //   'buttons': [],
  // },
]

const richEditorConfig = {
  buttons: richEditorButtons,
  buttonsMD: richEditorButtons,
  buttonsSM: richEditorButtons,
  buttonsXS: richEditorButtons,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  imageDefaultWidth: 400,
  enableDragAndDropFileToEditor: true,
  uploader: {
    url: `${process.env.BACKEND_HOST}/connect/v1/admin/doc_articles/upload`,
    format: 'json',
    headers: () => ({
      'X-Access-Token': authToken(),
    }),
    processFileName: (key, file, name) => {
      return ['files', file, name]
    },
    isSuccess: (response) => response,
    process: (response) => {
      return {
        files: response.data.urls,
        path: response.data.urls,
        baseurl: response.data.urls,
        error: response.message,
        message: response.message,
      }
    },
    defaultHandlerSuccess: function (data) {
      var i,
        field = 'files'
      if (data[field] && data[field].length) {
        for (i = 0; i < data[field].length; i += 1) {
          this.selection.insertImage(data[field][i])
        }
      }
    },
  },
}

export const RichEditor = (props) => {
  const { initialValue, onChange } = props

  const editor = useRef()
  const [loaded, setLoaded] = useState(false)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (!Jodit) {
      Jodit = require('jodit').Jodit
    }
    if (!JoditEditor) {
      JoditEditor = require('jodit-react').default
    }
    setLoaded(true)
  }, [setLoaded])

  const onEditorChange = useCallback(
    (newValue) => {
      setValue(newValue)
      onChange(newValue)
    },
    [onChange]
  )

  if (!loaded) {
    return null
  }

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={richEditorConfig}
      onChange={(newValue) => {}}
      onBlur={onEditorChange}
    />
  )
}

const plugins = [createToolbarPlugin(), createImagePlugin()]

export const RichEditor2 = (props) => {
  const { initialValue, onChange } = props

  const [editorState, setEditorState] = React.useState(() => {
    // EditorState.createWithContent(stateFromHTML(initialValue))
    const blocksFromHTML = convertFromHTML(initialValue)
    console.log(blocksFromHTML)
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    )
  })

  const onEditorChange = (newEditorState) => {
    setEditorState(newEditorState)
    console.log(stateToHTML(newEditorState.getCurrentContent()))
  }

  const onKeyCommand = (command) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command)
    if (newEditorState) {
      this.onChange(newEditorState)
      return 'handled'
    }
    return 'not-handled'
  }

  return (
    <Editor
      editorState={editorState}
      onChange={onEditorChange}
      handleKeyCommand={onKeyCommand}
      plugins={plugins}
    />
  )
}
