import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { uniqBy, compact } from 'lodash'
import moment from 'moment'
import { Title } from '../shared'
import { toUnicode } from 'punycode'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '16px 16px',
  },
  item: {
    marginBottom: 16,
  },
}))

export default function Status(props) {
  const classes = useStyles()

  const [data, setData] = useState(null)

  useEffect(() => {
    if (data === null) {
      API.get('ended_contracts').then((response) => {
        if (response) {
          setData(response.data)
        }
      })
    }
  }, [])

  if (!data) {
    return (
      <>
        <Title title="Anakin" />
      </>
    )
  }

  return (
    <>
      <Title title="Anakin" />
      <Paper className={classes.container}>
        <Content classes={classes} data={data} />
      </Paper>
    </>
  )
}

const Content = (props) => {
  const { classes, data } = props

  return (
    <div className={classes.content}>
      {data.contracts.map((contract) => (
        <Item key={contract.uuid} classes={classes} contract={contract} />
      ))}
    </div>
  )
}

const Item = (props) => {
  const { classes, contract } = props

  const isTerminated = !!contract.terminatedAt
  const isEnded = !isTerminated && !!contract.endedAt

  return (
    <div className={classes.item}>
      <div>
        <a href={`/contracts/${contract.uuid}`}>{contract.code}</a>
      </div>
      {isTerminated ? (
        <div>
          {contract.terminationReason === 'mutual'
            ? 'Mutual agreed termination'
            : 'Breach of agreement termination'}{' '}
          at {moment(contract.terminatedAt).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      ) : isEnded ? (
        <div>
          Contract expired at{' '}
          {moment(contract.endedAt).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      ) : (
        <div>Campaign is active (?)</div>
      )}
    </div>
  )
}
