import React, { useState, useEffect } from 'react'
import {
  LinearProgress,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field, FastField } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { defaultFormStyles, SelectField } from './shared'
import * as API from '../../services/api'

const contractSort = (a, b) => a.code - b.code
const publisherSort = (a, b) => a.name - b.name

const triggerKeyOptions = [
  { label: 'Coupon code', value: 'callbackOrderCouponCode' },
  { label: 'Order ID', value: 'callbackOrderId' },
  { label: 'Goal ID', value: 'callbackGoalId' },
  { label: 'Goal name', value: 'callbackGoalName' },
  // { label: 'Item name', value: 'itemName' },
  // { label: 'Item sku', value: 'itemSku' },
  // { label: 'Item variant', value: 'itemVariant' },
  // { label: 'Item url', value: 'itemProductUrl' },
  // { label: 'Item category', value: 'itemCategoryName' },
  // { label: 'Event key', value: 'eventKey' },
  // { label: 'Event type', value: 'eventType' },
  // { label: 'Event value', value: 'eventValue' },
  // { label: 'Marketing tag', value: 'callbackMarketingTag' },
]

const triggerRuleOptions = [
  { label: 'Equals', value: 'equals' },
  // { label: 'Not equals', value: 'notEquals' },
  { label: 'Contains', value: 'contains' },
  { label: 'Starts with', value: 'startsWith' },
  { label: 'Ends with', value: 'endsWith' },
]

const actionKeyOptions = [
  { label: 'Total payout', value: 'totalPayout' },
  // { label: 'Item payout', value: 'itemPayout' },
]

const actionRuleOptions = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
  { label: 'Set to', value: 'set' },
]

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        paddingTop: theme.spacing(1),
      },
    },

    '& .checkbox-container': {
      marginTop: -16,
    },

    '& .MuiAutocomplete-root': {
      marginTop: 32,
    },

    '& .hidden': {
      display: 'none',
    },

    '& .contract-info': {
      marginTop: 8,
      marginBottom: -8,
    },

    '& .swap-trigger-btn': {
      display: 'block',
      height: 16,
      padding: 0,
      margin: '0 auto',

      '&.hidden': {
        display: 'block',
        opacity: 0,
        pointerEvents: 'none',
      },
    },

    '& .remove-trigger-btn': {
      minWidth: 36,
      marginTop: 8,
    },
  },
  fieldset: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  fieldrow: {
    '& .MuiGrid-root': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  hint: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.info.light}`,
    color: theme.palette.grey[700],
  },
  autocompleteSelect: {
    marginTop: '16px !important',
    marginBottom: 16,

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 0,
      marginRight: 0,
    },

    '& .MuiButtonBase-root': {
      position: 'relative',
      top: 16,
    },
  },
}))

const findAdvertiserUuidByContractUuid = ({
  advertisers,
  contracts,
  contractUuid,
}) => {
  const contract = contracts.find((contract) => contract.uuid === contractUuid)
  return contract.advertiserUuid
}

export default function ContractRuleForm(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}

  const classes = useStyles()

  const [contracts, setContracts] = useState(null)
  const [advertisers, setAdvertisers] = useState(null)
  const [publishers, setPublishers] = useState(null)

  useEffect(() => {
    if (!contracts) {
      API.form('contract_rules').then((response) => {
        setContracts(response?.data?.contracts)
        setAdvertisers(response?.data?.advertisers)
        setPublishers(response?.data?.publishers)
      })
    }
  }, [contracts, advertisers, publishers])

  if (contracts === null || advertisers === null || publishers === null) {
    return <CircularProgress />
  }

  return (
    <Formik
      initialValues={{
        contractUuid: initialValues.contractUuid,
        advertiserUuid: initialValues.advertiserUuid,
        publisherUuid: initialValues.publisherUuid || null,
        isActive: !!initialValues.isActive || true,
        isUnique: initialValues.isUnique === null ? false : true,
        name: initialValues.name || '',
        triggerKey: initialValues.triggerKey || triggerKeyOptions[0].value,
        triggerRule: initialValues.triggerRule || triggerRuleOptions[0].value,
        triggerValue: initialValues.triggerValue || '',
        actionKey: initialValues.actionKey || actionKeyOptions[0].value,
        actionRule: initialValues.actionRule || actionRuleOptions[0].value,
        actionValue: initialValues.actionValue || '',
      }}
      validate={(values) => {
        const errors = {}
        const fields = [
          'name',
          'contractUuid',
          'triggerKey',
          'triggerRule',
          'triggerValue',
          'actionKey',
          'actionRule',
        ]
        fields.forEach((field) => {
          if (!values[field]) {
            errors[field] = 'Required'
          }
        })
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        values.contractUuid = values.contractUuid || null
        values.advertiserUuid = findAdvertiserUuidByContractUuid({
          advertisers,
          contracts,
          contractUuid: values.contractUuid,
        })
        values.publisherUuid = values.publisherUuid || null
        values.isUnique = values.isUnique || null
        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        const contract = contracts.find(
          (contract) =>
            contract.uuid ===
            (values.contractUuid || initialValues.contractUuid)
        )
        const advertiser = advertisers.find(
          (advertiser) => advertiser.uuid === contract?.advertiserUuid
        )
        const publisher =
          publishers?.find(
            (publisher) => publisher.uuid === values?.publisherUuid
          ) || contract?.publisher

        return (
          <Form className={classes.form} acceptCharset="UTF-8">
            <Grid container justify="center" alignItems="center">
              <Grid item sm={12} md={9}>
                <FastField
                  component={TextField}
                  variant="outlined"
                  size="small"
                  name="name"
                  label="Rule name"
                />

                <div className="checkbox-container">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="isActive"
                    onChange={(event) =>
                      setFieldValue('isActive', event.target.checked)
                    }
                    Label={{
                      label: 'Active',
                    }}
                  />
                </div>

                <div className={`${values.isDefault ? 'hidden' : ''}`}>
                  <Autocomplete
                    className={classes.autocompleteSelect}
                    name="contractUuid"
                    size="small"
                    options={[
                      null,
                      ...(contracts
                        ?.sort(contractSort)
                        .map((contract) => contract.uuid) || []),
                    ]}
                    getOptionLabel={(value) => {
                      const contract = contracts.find(
                        (contract) => contract.uuid === value
                      )
                      return contract?.code || '-- No campaign --'
                    }}
                    defaultValue={props.initialValues?.contractUuid}
                    onChange={(event, value) => {
                      setFieldValue('contractUuid', value)
                    }}
                    renderInput={(inputProps) => (
                      <Field
                        component={TextField}
                        variant="outlined"
                        size="small"
                        name="contractUuid"
                        label="Campaign"
                        {...inputProps}
                      />
                    )}
                  />

                  <Autocomplete
                    className={classes.autocompleteSelect}
                    name="publisherUuid"
                    size="small"
                    options={[
                      null,
                      ...(publishers
                        ?.sort(publisherSort)
                        .map((publisher) => publisher.uuid) || []),
                    ]}
                    getOptionLabel={(value) => {
                      const publisher = publishers.find(
                        (publisher) => publisher.uuid === value
                      )
                      return (
                        publisher?.name || '-- Default campaign publisher --'
                      )
                    }}
                    defaultValue={props.initialValues?.publisherUuid}
                    onChange={(event, value) => {
                      setFieldValue('publisherUuid', value || null)
                    }}
                    renderInput={(inputProps) => (
                      <Field
                        component={TextField}
                        variant="outlined"
                        size="small"
                        name="publisherUuid"
                        label="Publisher"
                        {...inputProps}
                      />
                    )}
                  />

                  <div className="contract-info">
                    {contract && (
                      <>
                        <div>Advertiser: {advertiser?.name}</div>
                        <div>Publisher: {publisher?.name}</div>
                        <div>
                          Commission model: {contract.data.commissionModel}
                        </div>
                        {contract.data.commissionModel === 'CPS' ? (
                          <div>
                            Default commission:{' '}
                            {contract.data.commissionPercentage}%
                          </div>
                        ) : (
                          <div>
                            Default commission: {contract.data.currency}{' '}
                            {contract.data.commissionPrice}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid container justify="center" alignItems="center">
              <Grid item sm={12} md={9}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="isUnique"
                  onChange={(event) =>
                    setFieldValue('isUnique', event.target.checked)
                  }
                  Label={{
                    label: 'Unique condition',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item sm={12} md={9}>
                <Typography variant="h6">Condition</Typography>
                <Typography variant="body2" className={classes.hint}>
                  Wildcard "*" can be used to match any string.
                  <br />
                  Condition value is case insensitive
                </Typography>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item sm={4}>
                    <SelectField
                      name="triggerKey"
                      label="Key"
                      initialValue={triggerKeyOptions[0].value}
                      values={triggerKeyOptions}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <SelectField
                      name="triggerRule"
                      label="Rule"
                      initialValue={triggerRuleOptions[0].value}
                      values={triggerRuleOptions}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FastField
                      component={TextField}
                      variant="outlined"
                      size="small"
                      name="triggerValue"
                      label="Value"
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6">Action</Typography>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item sm={4}>
                    <SelectField
                      name="actionKey"
                      label="Action key"
                      initialValue={actionKeyOptions[0].value}
                      values={actionKeyOptions}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <SelectField
                      name="actionRule"
                      label="Rule"
                      initialValue={actionRuleOptions[0].value}
                      values={actionRuleOptions}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      size="small"
                      name="actionValue"
                      label="Value"
                      disabled={
                        values.actionRule === 'approve' ||
                        values.actionRule === 'reject'
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />
            {isSubmitting && <LinearProgress />}
            <div className={classes.formActions}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {isNew ? 'Create New Rule' : 'Update Rule'}
              </Button>
              <Button
                to={
                  isNew
                    ? '/attributions'
                    : `/attributions/${initialValues.uuid}`
                }
              >
                Cancel
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
