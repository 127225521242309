import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { uniqBy, compact } from 'lodash'
import { Title } from '../shared'
import { toUnicode } from 'punycode'
// import * as API from '../../services/api'
import * as ScriptManagerAPI from '../../services/scriptapi'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: 4,
    boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.2)',
  },

  urlsInput: {
    width: '100%',
  },

  actionBtn: {},

  channelFilter: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 8,
    marginBottom: 16,
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,

    '&:hover': {
      borderColor: '#000000',
    },
  },

  channelSelect: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    borderColor: '#c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,
    appearance: 'none',

    '&:hover': {
      borderColor: '#000000',
    },
  },

  channelSelectPin: {
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 14,
      right: 16,
      width: 8,
      height: 8,
      borderRight: '2px solid #c0c0c0',
      borderBottom: '2px solid #c0c0c0',
      transform: 'rotate(45deg)',
      pointerEvents: 'none',
    },
  },

  countryFilter: {
    width: '100%',
    height: 40,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 16,
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    color: '#000000cc',
    background: 'white',
    fontSize: 16,

    '&:hover': {
      borderColor: '#000000',
    },
  },

  output: {
    border: '1px solid gray',
    borderRadius: 4,
    padding: theme.spacing(2),
    whiteSpace: 'pre',
    overflow: 'auto',
  },

  outputRow: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

// const findPublisher = (publishers, uuid) => {
//   return publishers.find((publisher) => publisher.uuid === uuid)
// }

// const publisherDetailsMemo = {}
// const findPublisherDetails = (publisherDetails, publisher) => {
//   if (!publisherDetails || !publisher) return undefined
//   if (publisherDetailsMemo[publisher.uuid])
//     return publisherDetailsMemo[publisher.uuid]
//
//   const foundDetails = publisherDetails.find((details) => {
//     if (
//       publisher.outboundAccountUuid &&
//       publisher.outboundAccountUuid === details.channel?.channelAccountUuid
//     ) {
//       return true
//     }
//     if (publisher.domain && details.contracts) {
//       return details.contracts
//         .map((contract) =>
//           contract.domain
//             .replace(/https?:\/\//, '')
//             .replace('www.', '')
//             .replace(/[/?#].*/, '')
//         )
//         .includes(
//           publisher.domain
//             .replace(/https?:\/\//, '')
//             .replace('www.', '')
//             .replace(/[/?#].*/, '')
//         )
//     }
//   })
//
//   if (foundDetails) {
//     return (publisherDetailsMemo[publisher.uuid] = {
//       details: foundDetails,
//       users: foundDetails.users
//         .map((user) =>
//           [user.name, user.email].filter((str) => !!str).join(' - ')
//         )
//         .join(', '),
//     })
//   }
//
//   return undefined
// }

const publisherToOption = (publisher) => {
  return {
    uuid: publisher.uuid,
    label: uniqBy(
      compact([
        publisher.name,
        toUnicode(publisher.domain),
        publisher.channel?.channelAccountName,
      ]),
      (str) =>
        str
          .toLowerCase()
          .replace(/https?:\/\//, '')
          .replace('www.', '')
          .replace(/[/?#].*/, '')
    ).join(' - '),
  }
}

export default function OutboundChecker(props) {
  const classes = useStyles()

  const [publishers, setPublishers] = useState(null)

  useEffect(() => {
    if (publishers === null) {
      ScriptManagerAPI.get('internal/publishers').then((response) => {
        if (response) {
          setPublishers(response)
        }
      })
    }
  }, [])

  if (!publishers) {
    return (
      <>
        <Title title="Outbound link checker" />
      </>
    )
  }

  return (
    <>
      <Title title="Outbound link checker" />
      <Paper className={classes.container}>
        <Content classes={classes} publishers={publishers} />
      </Paper>
    </>
  )
}

const Content = (props) => {
  const { classes, publishers } = props

  const [selectedPublisherUuid, setSelectedPublisherUuid] = useState(
    publishers[0].uuid
  )
  const [countryCode, setCountryCode] = useState('')
  const [urlsText, setUrlsText] = useState('')
  const [output, setOutput] = useState('')
  const [outputCountryless, setOutputCountryless] = useState('')
  const [showCountryless, setShowCountryless] = useState(false)

  const checkUrls = useCallback(() => {
    const urls = urlsText
      .split('\n')
      .map((url) => url.trim())
      .filter((url) => !!url)

    if (!urls.length) return

    setOutput('')
    setOutputCountryless('')

    window
      .fetch(
        `${process.env.HEYLINK_HOST}/tr/targets/v1/${selectedPublisherUuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            targetUrls: urls,
            country: countryCode || '',
          }),
        }
      )
      .then((raw) => raw.json())
      .then((data) => setOutput(data))

    if (countryCode) {
      window
        .fetch(
          `${process.env.HEYLINK_HOST}/tr/targets/v1/${selectedPublisherUuid}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
              targetUrls: urls,
            }),
          }
        )
        .then((raw) => raw.json())
        .then((data) => setOutputCountryless(data))
    }
  }, [selectedPublisherUuid, countryCode, urlsText])

  const [publisherSearch, setPublisherSearch] = useState('')
  const [shownPublishers, setShownPublishers] = useState([])

  const publisherSelectRef = useRef()

  useEffect(() => {
    let timeoutId = 0
    if (!publisherSearch) {
      setShownPublishers(publishers.map(publisherToOption))
    } else {
      timeoutId = setTimeout(() => {
        timeoutId = 0
        setShownPublishers(
          publishers
            .filter((publisher) => {
              return (
                publisher.name.toLowerCase().includes(publisherSearch) ||
                publisher.domain.toLowerCase().includes(publisherSearch) ||
                publisher.channel?.channelAccountName
                  ?.toLowerCase()
                  ?.includes(publisherSearch)
              )
            })
            .map(publisherToOption)
        )
      }, 400)
      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [publishers, publisherSearch])

  useEffect(() => {
    if (publisherSelectRef.current) {
      setSelectedPublisherUuid(publisherSelectRef.current.value)
    }
  }, [shownPublishers])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <label>Outbound account</label>
          <div>
            <input
              className={classes.channelFilter}
              placeholder="Search"
              value={publisherSearch}
              onChange={(event) => {
                setPublisherSearch(event.target.value)
              }}
            />
          </div>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item sm={10}>
              <div className={classes.channelSelectPin}>
                <select
                  ref={publisherSelectRef}
                  className={classes.channelSelect}
                  onChange={(event) => {
                    setSelectedPublisherUuid(event.target.value)
                  }}
                >
                  {shownPublishers.map((publisher) => (
                    <option key={publisher.uuid} value={publisher.uuid}>
                      {publisher.label}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item sm={2}>
              <input
                className={classes.countryFilter}
                placeholder="Country code (optional)"
                value={countryCode}
                onChange={(event) => {
                  setCountryCode(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <TextField
            className={classes.urlsInput}
            variant="outlined"
            label="URLs to check"
            value={urlsText}
            onChange={(event) => {
              setUrlsText(event.target.value)
            }}
            placeholder="test1.com&#10;test2.com"
            multiline
            rows={8}
          />
        </Grid>
        <Grid item sm={12}>
          <Button
            color="primary"
            variant="contained"
            className={classes.actionBtn}
            onClick={checkUrls}
          >
            Test
          </Button>
        </Grid>
        {output && (
          <Grid item sm={12}>
            <Box className={classes.output}>
              <Output classes={classes} output={output} />
            </Box>
          </Grid>
        )}
        {outputCountryless && (
          <Grid item sm={12}>
            <FormControlLabel
              label="Compare to without country"
              control={
                <Checkbox
                  checked={showCountryless}
                  onChange={(event) => setShowCountryless(event.target.checked)}
                />
              }
            />
            {showCountryless && (
              <Box className={classes.output}>
                <Output classes={classes} output={outputCountryless} />
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </>
  )
}

const Output = (props) => {
  const { classes, output } = props

  if (!output?.data?.length) {
    return <div>Nothing found</div>
  }

  return output.data.map((row) => (
    <div key={row.targetUrl} className={classes.outputRow}>
      <div>
        <b>{row.targetUrl}</b>
      </div>
      <div>
        {row.trackingUrl ? (
          <>
            Campaign found:{' '}
            <a href={row.trackingUrl} target="_blank" rel="noreferrer">
              {row.trackingUrl}
            </a>
          </>
        ) : (
          'No campaign found'
        )}
      </div>
    </div>
  ))
}
