import React, { useState, useEffect } from 'react'
import { CircularProgress, Button, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Toast } from '../shared'
import * as API from '../../services/api'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}))

export default function ConversionSafeguard(props) {
  const classes = useStyles()

  const [maxAmount, setMaxAmount] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (maxAmount === null) {
      API.get('settings/conversions').then((response) => {
        setMaxAmount(response.data.conversionMaxSafeAmount || '')
      })
    }
  }, [maxAmount])

  if (maxAmount === null) {
    return <CircularProgress />
  }

  const onChange = (callback) => (event) => callback(event.target.value)

  const onSubmit = async () => {
    const data = { conversionMaxSafeAmount: maxAmount }

    setIsSubmitting(true)
    API.put('settings/conversions', JSON.stringify(data))
      .then((response) => {
        setIsSubmitting(false)
        if (response?.data?.success) {
          setMessage('Options updated')
        } else if (response?.message) {
          setMessage(response.message)
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        setMessage(error)
      })
  }

  return (
    <>
      <Title title="Conversion Safeguard">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Title>
      <Paper className={classes.container}>
        <TextField
          label="Max safe conversion amount"
          type="number"
          value={maxAmount}
          onChange={onChange(setMaxAmount)}
          fullWidth
        />
      </Paper>
      <Toast message={message} setMessage={setMessage} />
    </>
  )
}
