import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Filter, { useFilterState } from './Filter'
import { Title, Pagination } from '../shared'
import * as API from '../../services/api'
import { navigateTo, pluralized } from '../../services/utils'

const useStyles = makeStyles((theme) => ({
  filter: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),

    '& .actions': {
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between',
    },

    '& .status-select': {
      '& .MuiFormLabel-root': {
        position: 'relative',
        top: 0,
        transition:
          'top 200ms, color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

        '&:not(.MuiInputLabel-shrink)': {
          top: 24,
        },
      },
      '& .MuiInputBase-root': {
        minWidth: 167,
      },
    },
  },
  table: {
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
}))

const perPage = 50

export default function ResourceList(props) {
  const { resource, resourcePath, title, fields } = props
  const classes = useStyles()

  const [records, setRecords] = useState(null)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  const filterState = useFilterState()

  useEffect(() => {
    if (records === null) {
      API.all(resource, { page, perPage, ...filterState.filter }).then(
        (response) => {
          if (response) {
            setRecords(response.data.records)
            setTotal(response.data.total)
          }
        }
      )
    }
  }, [resource, records, page, filterState.filter])

  useEffect(() => {
    setRecords(null)
    setTotal(0)
    setPage(1)
  }, [filterState.filter])

  if (records === null) {
    return (
      <>
        <Title title={pluralized(title)} />

        <Filter className={classes.filter} state={filterState} />

        <CircularProgress />
      </>
    )
  }

  const gotoPage = (nextPage) => {
    setPage(nextPage)
    setRecords(null)
  }

  // const deleteRecord = async (uuid) => {
  //   if (window.confirm('Are you sure?')) {
  //     await API.destroy(resource, uuid)
  //     setRecords(null)
  //   }
  // }

  return (
    <>
      <Title title={pluralized(title)} />

      <Filter className={classes.filter} state={filterState} />

      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {Object.keys(fields).map((field) => (
                <TableCell key={field}>{field}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records &&
              records.map((record) => (
                <TableRow
                  key={record.callbackUuid}
                  onClick={(event) => {
                    if (!event.target.closest('a, button')) {
                      navigateTo(
                        event,
                        `/${resourcePath}/${record.callbackUuid}`
                      )
                    }
                  }}
                >
                  {Object.keys(fields).map((field) =>
                    typeof fields[field] === 'string' ? (
                      <TableCell key={`${record.callbackUuid}/${field}`}>
                        {record[fields[field]]}
                      </TableCell>
                    ) : (
                      <TableCell key={`${record.callbackUuid}/${field}`}>
                        {fields[field](record)}
                      </TableCell>
                    )
                  )}
                  <TableCell align="right">
                    {/*
                    <Button
                      color="primary"
                      size="small"
                      to={`/${resourcePath}/${record.callbackUuid}/edit`}
                    >
                      Edit
                    </Button>
                    <MaterialButton
                      color="secondary"
                      size="small"
                      onClick={() => deleteRecord(record.callbackUuid)}
                    >
                      Delete
                    </MaterialButton>
                    */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination {...{ page, perPage, gotoPage, total }} />
    </>
  )
}
