import React, { useState, useEffect } from 'react'
import { LinearProgress, TextareaAutosize, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'gatsby-theme-material-ui'
import { Formik, Form, Field } from 'formik'
import {
  TextField,
  SimpleFileUpload,
  CheckboxWithLabel,
} from 'formik-material-ui'
import { defaultFormStyles } from './shared'
import * as API from '../../services/api'
import { countryCodes, countryLabels } from '../../services/countries'

const useStyles = makeStyles((theme) => ({
  form: {
    ...defaultFormStyles(theme),

    '& .MuiCheckbox-root': {
      margin: theme.spacing(1),
    },

    '& .label': {
      display: 'block',
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
    },

    '& input[type="file"]': {
      width: 0,
      color: 'transparent',
    },

    '& input[type="file"]::-webkit-file-upload-button': {
      visibility: 'hidden',
    },

    '& .custom-file-input': {
      display: 'inline-block',
      padding: '5px 8px',
      marginRight: theme.spacing(2),
      border: '1px solid #999',
      borderRadius: 4,
      color: 'inherit',
      background: 'transparent',
      outline: 'none',
      whiteSpace: 'nowrap',
      userSelect: 'none',
      cursor: 'pointer',

      '&:hover': {
        background: '#eee',
      },
    },

    '& .listed-source': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),

      '& .MuiFormControlLabel-root': {
        display: 'block',
        marginBottom: -theme.spacing(2),
      },

      '& .MuiFormControl-root': {
        display: 'block',
        marginBottom: theme.spacing(5),
      },
    },

    '& .description-field': {
      '& label': {
        display: 'block',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.54)',
      },

      '& textarea': {
        width: '100% !important',
        padding: 8,
        fontFamily: 'inherit',
        fontSize: 16,
      },
    },

    '& .logo-upload': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),

      '& img': {
        width: 120,
        height: 50,
        marginRight: theme.spacing(2),
        objectFit: 'contain',
      },

      '& .file-field': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },

    '& .exposure-fields': {
      '& .exposure-images': {
        marginTop: theme.spacing(1),

        '& .exposure-image': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),

          '& > img': {
            width: 100,
            height: 100,
            marginRight: theme.spacing(2),
            objectFit: 'cover',
          },

          '& .exposure-image-order': {
            display: 'flex',
            flexDirection: 'column',
            marginRight: theme.spacing(2),

            '& button': {
              width: 30,
              height: 30,
              border: 'none',
              borderRadius: 4,
              color: '#4051b5',
              background: 'transparent',
              cursor: 'pointer',

              '&:hover': {
                background: '#eee',
              },

              '&[disabled]': {
                background: 'transparent !important',
                opacity: 0.5,
                pointerEvents: 'none',
              },
            },

            '& .exposure-order-button-placeholder': {
              width: 30,
              height: 30,
            },

            '& img': {
              width: 12,
            },

            '& .order-up': {
              '& img': {
                position: 'relative',
                top: 3,
                transform: 'rotate(180deg)',
              },
            },
          },
        },

        '& .insert-new': {
          position: 'relative',
          border: 'none',
          color: '#4051b5',
          background: 'transparent',
          cursor: 'pointer',

          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },

    '&.is-submitting': {
      '& .logo-upload': {
        pointerEvents: 'none',
      },

      '& .exposure-fields': {
        pointerEvents: 'none',
      },
    },
  },
}))

const useExposureFiles = (initialValues) => {
  const newExposureFile = (url, index) => ({
    originalIndex: index,
    index: index,
    preview: url,
    url: url,
    file: null,
    remove: false,
  })

  const [exposureFiles, setExposureFiles] = useState([])
  useEffect(() => {
    if (initialValues?.exposureImageUrls) {
      setExposureFiles(
        initialValues.exposureImageUrls.map((url, index) =>
          newExposureFile(url, index)
        )
      )
    } else {
      if (exposureFiles.length) {
        setExposureFiles([])
      }
    }
  }, [initialValues])

  const insertNewExposureFile = (event) => {
    event.preventDefault()
    const newIndex = exposureFiles.length
    setExposureFiles([...exposureFiles, newExposureFile('', newIndex)])
  }

  const setExposureFile = (toSet) => (event) => {
    event.preventDefault()
    const newExposureFiles = [...exposureFiles]
    const file = newExposureFiles.find((file) => file.index === toSet.index)

    file.file = event.target.files[0]

    setExposureFiles(newExposureFiles)
  }

  const moveExposureFile = (toMove, dir) => (event) => {
    event.preventDefault()
    const newExposureFiles = [...exposureFiles]
    const file = newExposureFiles.find((file) => file.index === toMove.index)
    const otherFile = newExposureFiles.find(
      (file) => file.index === toMove.index + dir
    )

    newExposureFiles.splice(
      file.index + dir,
      0,
      newExposureFiles.splice(file.index, 1)[0]
    )
    file.index += dir
    otherFile.index -= dir

    setExposureFiles(newExposureFiles)
  }

  const toggleExposureFileRemove = (toRemove) => (event) => {
    event.preventDefault()
    const newExposureFiles = [...exposureFiles]
    const file = newExposureFiles.find((file) => file.index === toRemove.index)

    file.remove = !file.remove

    setExposureFiles(newExposureFiles)
  }

  const updateExposurePreview = (previewFile) => (event) => {
    const parent = event.target.closest('.file-field')
    if (!parent) {
      return
    }

    let img = parent.querySelector('img')
    if (!img) {
      img = document.createElement('img')
      parent.prepend(img)
    }

    const file = event.target.files && event.target.files[0]
    if (!file) {
      return
    }

    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const newExposureFiles = [...exposureFiles]
      const updateFile = newExposureFiles.find(
        (file) => file.index === previewFile.index
      )
      updateFile.preview = e.target.result
      setExposureFiles(newExposureFiles)
    }
  }

  return {
    exposureFiles,
    insertNewExposureFile,
    setExposureFile,
    moveExposureFile,
    toggleExposureFileRemove,
    updateExposurePreview,
  }
}

const uploadExposureImages = async (exposureFiles) => {
  const result = exposureFiles
    .filter((file) => !file.remove)
    .map((file) => file.url || '')

  const formData = new FormData()
  const uploadMap = []
  for (const index in exposureFiles) {
    const file = exposureFiles[index]
    if (file.file && !file.remove) {
      formData.append('files', file.file)
      uploadMap.push(index)
    }
  }

  if (!uploadMap.length) {
    return result
  }

  const response = await API.upload('publishers', formData, 'exposure')

  for (const index in response.data.urls) {
    const url = response.data.urls[index]
    result[uploadMap[index]] = url
  }

  return result
}

const uploadLogoImage = async (values) => {
  if (values.logoFileRemove) {
    return ''
  } else if (values.logoFile) {
    const formData = new FormData()
    formData.append('file', values.logoFile)

    const response = await API.upload('publishers', formData, 'logo')

    return response?.data?.url || ''
  }
}

export default function PublisherForm(props) {
  const { isNew, onSubmit } = props

  const initialValues = props.initialValues || {}
  const initialCompanyValue = { ...initialValues.company }

  const classes = useStyles()

  const {
    exposureFiles,
    insertNewExposureFile,
    setExposureFile,
    moveExposureFile,
    toggleExposureFileRemove,
    updateExposurePreview,
  } = useExposureFiles(initialValues)

  const updateLogoPreview = (event) => {
    const parent = event.target.closest('.file-field')
    if (!parent) {
      return
    }

    let img = parent.querySelector('img')
    if (!img) {
      img = document.createElement('img')
      parent.prepend(img)
    }

    const file = event.target.files && event.target.files[0]
    if (!file) {
      return
    }

    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => (img.src = e.target.result)
  }

  return (
    <Formik
      initialValues={{
        name: initialValues.name || '',
        email: initialValues.email || '',
        description: initialValues.description || '',
        company: initialCompanyValue,
        isAdmin: !!initialValues.isAdmin || false,
        isListedSource: !!initialValues.isListedSource || false,
        sourceAttraction: initialValues.sourceAttraction || '',
        whitelabelUrl: initialValues.whitelabelUrl || '',
        tuneAffiliateId: initialValues.tuneAffiliateId || '',
        exposureText: initialValues.exposureText || '',
        exposureImageUrls: initialValues.exposureImageUrls || [],
        logoFile: initialValues.logoFile || '',
        logoFileRemove: false,
      }}
      validate={(values) => {
        const errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)

        values.exposureImageUrls = await uploadExposureImages(exposureFiles)
        values.logoUrl = await uploadLogoImage(values)

        if (!values.tuneAffiliateId) {
          values.tuneAffiliateId = null
        }

        await onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, ...formik }) => (
        <Form
          className={`${classes.form} ${isSubmitting ? 'is-submitting' : ''}`}
          acceptCharset="UTF-8"
        >
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Publisher name"
          />
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Publisher email"
          />
          <Field
            component={TextField}
            name="tuneAffiliateId"
            type="text"
            label="Publisher TUNE ID"
          />
          <Field
            component={TextField}
            name="whitelabelUrl"
            type="text"
            label="Partner network URL"
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="isAdmin"
            onChange={(event) => setFieldValue('isAdmin', event.target.checked)}
            Label={{ label: 'Admin (access to all agreements)' }}
          />
          <div className="description-field">
            <label htmlFor="publisher_description">Publisher description</label>
            <Field name="description">
              {(props) => (
                <TextareaAutosize
                  id="publisher_description"
                  rowsMin={4}
                  defaultValue={initialValues.description || ''}
                  onChange={(event) =>
                    setFieldValue('description', event.target.value)
                  }
                />
              )}
            </Field>
          </div>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Field
                component={TextField}
                name="company.name"
                type="text"
                label="Company name"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.address"
                type="text"
                label="Address"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.city"
                type="text"
                label="City"
                fullWidth
              />
              <Field
                component={TextField}
                name="company.zip"
                type="text"
                label="Zipcode"
                fullWidth
              />
              <Autocomplete
                className={classes.autocompleteSelect}
                name="company.country"
                options={countryCodes}
                getOptionLabel={(value) => countryLabels[value] || ''}
                defaultValue={initialCompanyValue?.country}
                onChange={(event, value) => {
                  setFieldValue('company.country', value)
                }}
                renderInput={(inputProps) => (
                  <Field
                    component={TextField}
                    name="country"
                    label="Country"
                    {...inputProps}
                  />
                )}
              />
              <Field
                component={TextField}
                name="company.vat"
                type="text"
                label="VAT number"
                fullWidth
              />
            </Grid>
          </Grid>
          <br />

          <div className="logo-upload">
            <div className="label">Logo</div>
            <div className="file-field">
              {!isNew && initialValues.logoUrl && (
                <img src={initialValues.logoUrl} alt="Logo preview" />
              )}
              <button
                onClick={(event) => {
                  event.preventDefault()
                  event.target.parentNode.querySelector('input').click()
                }}
                className="custom-file-input"
              >
                Choose file
              </button>
              <Field
                component={SimpleFileUpload}
                name="logoFile"
                type="file"
                InputProps={{
                  onChange: (e) => {
                    setFieldValue('logoFile', e.target.files[0])
                    updateLogoPreview(e)
                  },
                }}
              />
            </div>
            {!isNew && (
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="logoFileRemove"
                Label={{ label: 'Remove publisher logo' }}
              />
            )}
          </div>

          <div className="exposure-fields">
            <Field
              component={TextField}
              name="exposureText"
              type="text"
              label="Exposure block text"
              fullWidth
            />
            <div className="exposure-images">
              <div className="label">Exposure examples</div>
              {exposureFiles?.map((file, i) => (
                <div className="exposure-image file-field" key={file.index}>
                  <img src={file.preview || file.url || null} alt="" />
                  <div className="exposure-image-order">
                    <button
                      onClick={moveExposureFile(file, -1)}
                      className="order-up"
                      disabled={i === 0}
                    >
                      <img src="/images/arrow-down.svg" alt="Move up" />
                    </button>
                    <button
                      onClick={moveExposureFile(file, +1)}
                      className="order-down"
                      disabled={i === exposureFiles.length - 1}
                    >
                      <img src="/images/arrow-down.svg" alt="Move down" />
                    </button>
                  </div>
                  <button
                    onClick={(event) => {
                      event.preventDefault()
                      event.target.parentNode.querySelector('input').click()
                    }}
                    className="custom-file-input"
                  >
                    Choose file
                  </button>
                  <Field
                    component={SimpleFileUpload}
                    name="exposureImageFile[]"
                    type="file"
                    InputProps={{
                      onChange: (e) => {
                        setExposureFile(file)(e)
                        updateExposurePreview(file)(e)
                      },
                    }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    name="exposureImageFileRemove[]"
                    type="checkbox"
                    value={file.remove}
                    checked={file.remove}
                    onChange={toggleExposureFileRemove(file)}
                    Label={{ label: 'Remove' }}
                  />
                </div>
              ))}
              <button onClick={insertNewExposureFile} className="insert-new">
                + Add
              </button>
            </div>
          </div>

          <div className="listed-source">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="isListedSource"
              onChange={(event) =>
                setFieldValue('isListedSource', event.target.checked)
              }
              Label={{ label: 'Listed source' }}
            />
            <Field
              component={TextField}
              name="sourceAttraction"
              type="text"
              label="Listed source attraction"
              placeholder="e.g. 250k users"
            />
          </div>

          {isSubmitting && <LinearProgress />}
          <div className={classes.formActions}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isNew ? 'Create Publisher' : 'Update Publisher'}
            </Button>
            <Button
              to={isNew ? '/publishers' : `/publishers/${initialValues.uuid}`}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
