import React, { useEffect, useState } from 'react'
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Filter, { useFilterState } from './Filter'
import { Title } from '../shared'
import * as API from '../../services/api'
import { navigateTo, pluralized } from '../../services/utils'

const useStyles = makeStyles((theme) => ({
  filter: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),

    '& .actions': {
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between',
    },

    '& .status-select': {
      '& .MuiFormLabel-root': {
        position: 'relative',
        top: 0,
        transition:
          'top 200ms, color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

        '&:not(.MuiInputLabel-shrink)': {
          top: 24,
        },
      },
      '& .MuiInputBase-root': {
        minWidth: 167,
      },
    },
  },
  table: {
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
  loadMore: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}))

const perPage = 250

export default function ResourceList(props) {
  const { resource, resourceKey, resourcePath, title, fields } = props
  const classes = useStyles()

  const [records, setRecords] = useState(null)
  const [nextPage, setNextPage] = useState('')

  const filterState = useFilterState()

  useEffect(() => {
    if (records === null) {
      API.all(resource, {
        perPage,
        ...filterState.filter,
      }).then((response) => {
        if (response?.data) {
          setRecords(response.data.records)
          setNextPage(response.data.nextPage)
        }
      })
    }
  }, [resource, records, filterState.filter])

  useEffect(() => {
    setRecords(null)
    setNextPage('')
  }, [filterState.filter])

  if (records === null) {
    return (
      <>
        <Title title={pluralized(title)} />

        <Filter className={classes.filter} state={filterState} />

        <CircularProgress />
      </>
    )
  }

  const loadMore = async () => {
    const page = nextPage
    const response = await API.all(resource, {
      page,
      perPage,
    })
    if (response?.data) {
      setRecords([...records, ...response.data.records])
      setNextPage(response.data.nextPage)
    }
  }

  return (
    <>
      <Title title={pluralized(title)} />

      <Filter className={classes.filter} state={filterState} />

      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {Object.keys(fields).map((field) => (
                <TableCell key={field}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {records &&
              records.map((record) => (
                <TableRow
                  key={record[resourceKey]}
                  onClick={(event) => {
                    if (!event.target.closest('a, button')) {
                      navigateTo(
                        event,
                        `/${resourcePath}/${record[resourceKey]}`
                      )
                    }
                  }}
                >
                  {Object.keys(fields).map((field) =>
                    typeof fields[field] === 'string' ? (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {record[fields[field]]}
                      </TableCell>
                    ) : (
                      <TableCell key={`${record.uuid}/${field}`}>
                        {fields[field](record)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {nextPage && (
        <div className={classes.loadMore}>
          <MaterialButton
            color="primary"
            variant="contained"
            onClick={loadMore}
          >
            Load more
          </MaterialButton>
        </div>
      )}
    </>
  )
}
